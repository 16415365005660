<template>
  <div>
    <b-modal
      ref="modalDataConvertContactToLeadChangeTabs"
      :title-html="((saveShare === true) ? $t('Escollher o separador para o Lead {name}', { name: '<b>' + nameLead + '</b>'}) : $t('Transferir o contacto {name} para Lead', { name: '<b>' + nameLead + '</b>'}))"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="((saveShare === true) ? $t('Guardar') : $t('Transferir'))"
      :cancel-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :cancel-disabled="showProcessing"
      :ok-disabled="showProcessing"
      @ok="saveModal"
      @cancel="hideModal"
    >
      <b-overlay
        :show="showProcessing"
        rounded="sm"
      >
        <validation-observer
          ref="formDataConvertContactToLeadChangeTabs"
          #default="{}"
        >
          <b-row
            v-if="modalActionTabOptions.length > 0"
          >
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Separador')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="modalActionTab"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="modalActionTabOptions"
                    append-to-body
                    :calculate-position="withPopper"
                    :clearable="true"
                    :placeholder="$t('Escolha um separador')"
                    label="name"
                    item-text="name"
                    item-value="tab"
                  >
                    <template #option="{ name }">
                      {{ name }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="modalActionBookmarksOptions.length > 0"
          >
            <b-col md="12">

              <b-form-group
                :label="$t('Marcadores')"
              >
                <v-select
                  v-model="modalActionBookmarks"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="modalActionBookmarksOptions"
                  append-to-body
                  :calculate-position="withPopper"
                  :multiple="true"
                  label="sw075s03"
                  item-text="sw075s03"
                  item-value="sw075s01"
                >
                  <template #option="{ sw075s03, sw075s04 }">
                    <feather-icon
                      icon="BookmarkIcon"
                      size="18"
                      class="mr-75"
                      :style="{color: sw075s04}"
                    />
                    {{ sw075s03 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
              </b-form-group>

            </b-col>
          </b-row>
        </validation-observer>
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              {{ $t('Aguarde, a processar...') }}
            </p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import btoa from 'btoa'
import checkNested from 'check-nested'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BOverlay,
  },
  mixins: [mixFormGeral],
  data() {
    return {
      nameLead: null,
      keyReg: null,
      showProcessing: false,
      modalActionTab: null,
      modalActionTabOptions: [],
      modalActionBookmarks: [],
      modalActionBookmarksOptions: [],
      saveShare: false,
      resolve: null,
    }
  },
  methods: {
    showModal(params) {
      this.resetFields()
      this.nameLead = params.nameLead || ''
      this.keyReg = params.keyReg || null
      this.modalActionTabOptions = params.tabs || []
      this.modalActionBookmarksOptions = params.bookmarks || []
      this.saveShare = params.saveShare || false
      this.resolve = params.resolve || null
      this.$refs.modalDataConvertContactToLeadChangeTabs.show()
    },

    async hideModal() {
      this.resetFields()
      this.$refs.modalDataConvertContactToLeadChangeTabs.hide()
    },

    resetFields() {
      this.nameLead = null
      this.keyReg = null
      this.showProcessing = false
      this.modalActionTab = null
      this.modalActionTabOptions = []
      this.modalActionBookmarks = []
      this.modalActionBookmarksOptions = []
      this.saveShare = false
      this.resolve = null
    },

    async saveModal(evt) {
      evt.preventDefault()
      await this.$refs.formDataConvertContactToLeadChangeTabs.validate()
        .then(async valid => {
          if (valid === true) {
            this.showProcessing = true

            let bookmarksSel = ''
            if (this.modalActionBookmarks.length > 0) {
              this.modalActionBookmarks.forEach(bookmark => {
                if (checkNested(bookmark, 'sw075s01') && (bookmark.sw075s01 !== '')) {
                  bookmarksSel += ((bookmarksSel) ? ';' : '') + btoa(bookmark.sw075s01)
                }
              })
            }

            await this.$store.dispatch('crm_leads_events/saveTabLeads', {
              sw025s01: this.keyReg,
              tab: this.modalActionTab.tab,
              bookmarks: bookmarksSel,
            }).then(async resSave => {
              if (this.saveShare === true) {
                this.resolve(resSave)
                this.hideModal()
              } else if (Number(resSave.sw025s85) === 0) {
                this.$parent.convertContactoToLeadLink(this.keyReg)
              } else {
                this.$store.dispatch('crm_leads_events/convertContactoToLead', { sw025s01: this.keyReg }).then(async msgSuccess => {
                  this.showProcessing = false
                  if (msgSuccess) {
                    this.showMsgSuccessRequest({ message: msgSuccess }).then(() => {
                      this.hideModal()
                      this.$parent.closeModalActionByRow()
                      this.$parent.showTabsListviewWithRecords()
                      this.$parent.fetchListView()
                    })
                  }
                }).catch(error => {
                  this.showProcessing = false
                  this.showMsgErrorRequest(error)
                })
              }
            }).catch(error => {
              this.showProcessing = false
              this.showMsgErrorRequest(error)
            })
          } else if (valid === false) {
            this.showMsgErrorRequest({ message: this.$t('Tem de escolher um separador') })
          }
        })
    },
  },
}
</script>
