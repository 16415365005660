var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalDataConvertContactToLeadChangeTabs",attrs:{"title-html":((_vm.saveShare === true) ? _vm.$t('Escollher o separador para o Lead {name}', { name: '<b>' + _vm.nameLead + '</b>'}) : _vm.$t('Transferir o contacto {name} para Lead', { name: '<b>' + _vm.nameLead + '</b>'})),"cancel-variant":"outline-secondary","size":"lg","body-class":"","visible":false,"ok-only":false,"ok-title":((_vm.saveShare === true) ? _vm.$t('Guardar') : _vm.$t('Transferir')),"cancel-title":_vm.$t('Fechar'),"ok-variant":"primary","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","cancel-disabled":_vm.showProcessing,"ok-disabled":_vm.showProcessing},on:{"ok":_vm.saveModal,"cancel":_vm.hideModal}},[_c('b-overlay',{attrs:{"show":_vm.showProcessing,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" "+_vm._s(_vm.$t('Aguarde, a processar...'))+" ")])])]},proxy:true}])},[_c('validation-observer',{ref:"formDataConvertContactToLeadChangeTabs",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.modalActionTabOptions.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Separador')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.modalActionTabOptions,"append-to-body":"","calculate-position":_vm.withPopper,"clearable":true,"placeholder":_vm.$t('Escolha um separador'),"label":"name","item-text":"name","item-value":"tab"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,true),model:{value:(_vm.modalActionTab),callback:function ($$v) {_vm.modalActionTab=$$v},expression:"modalActionTab"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.modalActionBookmarksOptions.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Marcadores')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.modalActionBookmarksOptions,"append-to-body":"","calculate-position":_vm.withPopper,"multiple":true,"label":"sw075s03","item-text":"sw075s03","item-value":"sw075s01"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var sw075s03 = ref.sw075s03;
var sw075s04 = ref.sw075s04;
return [_c('feather-icon',{staticClass:"mr-75",style:({color: sw075s04}),attrs:{"icon":"BookmarkIcon","size":"18"}}),_vm._v(" "+_vm._s(sw075s03)+" ")]}}],null,true),model:{value:(_vm.modalActionBookmarks),callback:function ($$v) {_vm.modalActionBookmarks=$$v},expression:"modalActionBookmarks"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)],1)],1):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }